import {
  AbstractMcpDeviceService,
  McpActiveProcessService
} from './core/services';
import {
  ActivatedRoute,
  Data,
  Event,
  NavigationEnd,
  Router
} from '@angular/router';
import packageInfo from '../../package.json';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable, filter, map, mergeMap, tap } from 'rxjs';
import { StatusBar } from '@capacitor/status-bar';

import { ActiveProcessEnum } from './core/enums';
import { ErrorHandlingOverlayComponent } from './core/components';
import { ROUTER_CFG_PROCESS_VALUE_TO_SET } from './core/constants';
import { Breadcrumb, MenuItem } from './shared/interfaces/interfaces';
import { MENU_ITEMS } from './app.config';
import { ROUTES } from './app-routing.config';
import { I18N_PREFIX, LanguageService, REMOTE_LANG_PREFIX } from './shared/services/language.service';
import { MatDrawer } from '@angular/material/sidenav';
import { RestService } from './core/services/rest.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { translate, TranslocoService } from '@ngneat/transloco';
import { RoutingHistoryService } from './shared/services/routing-history.service';
import { getDeepCopy } from './utils';
import { CommId } from './core/services/broadcast.service';
import { StorageService } from './shared/services/storage.service';
import { BuildTarget, LOCALE } from './shared/enums/enums';
import { KCAuthGuard } from './core/guards/kc-auth.guard';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from './shared/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'mcp-app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements OnInit {
  @ViewChild(ErrorHandlingOverlayComponent)
  errorHandlingOverlayComponent: ErrorHandlingOverlayComponent;
  areBroadcastItemsInitialized = false;

  @ViewChild('drawer', { static: true }) drawer: MatDrawer;
  @ViewChild('drawerRight', { static: true }) drawerRight: MatDrawer;

  breadcrumbs: Breadcrumb[] = [];
  menuItems: MenuItem[];
  headerIsVisible = false;
  sidebarIsVisible = false;
  sidebarRightIsVisible = false;
  sidebarRightShouldBeVisible = false;
  hasButtons = true;
  errorOccured$: Observable<number> = this._mcpDeviceService
    .getErrorOccured$()
    .pipe(tap(() => this.errorHandlingOverlayComponent.show()));
  appVersion = packageInfo.version;

  constructor(
    private readonly _router: Router,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _mcpActiveProcessService: McpActiveProcessService,
    private readonly _languageService: LanguageService,
    private readonly _mcpDeviceService: AbstractMcpDeviceService,
    private readonly _restService: RestService,
    private readonly _snackBar: MatSnackBar,
    private readonly _routingHistoryService: RoutingHistoryService,
    private readonly _translateService: TranslocoService,
    private readonly _storageService: StorageService,
    private readonly _authGuard: KCAuthGuard,
    private readonly _authService: AuthService
  ) {
    this._mcpActiveProcessService
      .getCurrentlyActiveProcess$()
      .subscribe((activeProcess) =>
        console.log(`MCPAPP::Process := ${activeProcess}`)
      );
      console.log('info: ', packageInfo);
  }

  public async ngOnInit() {
    if (environment.buildTarget === BuildTarget.NATIVE) {
      this.configureStatusBar();
    }

    this._languageService.init();
    this._routingHistoryService.init();
    this._authService.init();
    this._restService.errorUpdate.subscribe((error: unknown) => {
      const httpError = error as HttpErrorResponse;
      if (httpError.status === 403) {
        this._snackBar.open(
          translate('common.missingRights'),
          translate('button.ok'), {
          duration: 6000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: 'snackbar-warn'
        });
      } else {
        console.log('Rest Error: ', error);
      }
    });
    this._router.events
      .pipe(
        filter((event: Event) => event instanceof NavigationEnd),
        tap(this._setHeaderIsVisible.bind(this)),
        tap(this._setSidebarIsVisible.bind(this)),
        map(() => this._activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }

          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe(this._setActiveProcessBasedOnRouteData.bind(this));

    await this._loadLatestLaguageData();
    try {
      // check app version
      const remoteAppVersion = await this._restService.getCurrentAppVersion();
      this._compareAppVersions(remoteAppVersion, this.appVersion);
    } catch (error) {
      console.log('[App] onInit - getCurrentAppVersion - error: ', error);
    }

    this.menuItems = getDeepCopy(MENU_ITEMS).map((item: MenuItem) => item.disabled = true);
    this.sidebarRightIsVisible = false;
    this._mcpDeviceService.initialBroadcastsRegistered$.subscribe(areRegistered => {
      if (areRegistered) {
        this.areBroadcastItemsInitialized = areRegistered;
        this.menuItems = [...MENU_ITEMS];
        this.sidebarRightIsVisible = !this.areBroadcastItemsInitialized ? false : this.sidebarRightShouldBeVisible;
      }
    });
    this._mcpDeviceService.updateOnCommError$.subscribe(item => {
      const relevantIds = [CommId.ID_LEARNING_COMMAND, CommId.ID_IMPULS_TARGET, CommId.ID_LEARNING_IME_POSITION];
      if (item && item.id && relevantIds.indexOf(item.id) > -1) {
        this._snackBar.open(
          this._translateService.translate('common.writeDataFailure'),
          this._translateService.translate('button.ok'), {
          duration: 6000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: 'snackbar-warn'
        });
      }
    });
  }

  public navItemClicked(item: MenuItem) {
    this._router.navigate(item.route);
  }

  public logoClicked() {
    this._router.navigate([ROUTES.MAIN]);
  }

  private async configureStatusBar() {
    await StatusBar.setOverlaysWebView({ overlay: false });
  }

  private _compareAppVersions(remoteAppVersion: string, localAppVersion: string) {
    if (remoteAppVersion !== localAppVersion) {
      const message = translate(
        'newAppVersion'
      );
      const ok = translate('common.ok');
      this._snackBar.open(message, ok, {
        duration: 20000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: 'snackbar-warn'
      });
    } else {
      console.log('[App] _compareAppVersions - same versions');
    }
  }

  private async _loadLatestLaguageData() {
    try {
      // load and set main languages
      const english = await this._restService.getTranslation(REMOTE_LANG_PREFIX + I18N_PREFIX + LOCALE.ENGLISH);
      this._storageService.setLanguageData(I18N_PREFIX + LOCALE.ENGLISH, english);
      const german = await this._restService.getTranslation(REMOTE_LANG_PREFIX + I18N_PREFIX + LOCALE.GERMAN);
      this._storageService.setLanguageData(I18N_PREFIX + LOCALE.GERMAN, german);

    } catch (error) {
      console.log('[App] _loadLatestLanguageData - getTranslation - error: ', error);
    }
  }

  private _setHeaderIsVisible(event: Event): void {
    const navigationEnd = event as NavigationEnd;

    this.headerIsVisible = !['/', '/start'].includes(navigationEnd.url);
  }

  private _setSidebarIsVisible(event: Event): void {
    const navigationEnd = event as NavigationEnd;
    this.sidebarIsVisible = this.sidebarRightShouldBeVisible = ![
      '/',
      '/start',
      '/device-prep',
      '/device-discovery',
      '/device-connected',
      '/connection-lost',
      '/main/installation/end-position-opened-setup',
      '/main/installation/end-position-closed-setup',
      '/main/installation/change-end-position-opened',
      '/main/installation/change-end-position-closed',
      '/main/installation/change-intermediate-position-closed-state',
      '/main/installation/change-intermediate-position-opened-state'
    ].includes(navigationEnd.url);
    if (this.areBroadcastItemsInitialized) {
      this.sidebarRightIsVisible = this.sidebarRightShouldBeVisible;
    }
  }

  private _setActiveProcessBasedOnRouteData(data: Data): void {
    const transformedData: {
      [ROUTER_CFG_PROCESS_VALUE_TO_SET]?: ActiveProcessEnum;
    } = data;

    if (!transformedData[ROUTER_CFG_PROCESS_VALUE_TO_SET]) {
      return;
    }

    this._mcpActiveProcessService.setCurrentlyActiveProcess(
      transformedData[ROUTER_CFG_PROCESS_VALUE_TO_SET]!
    );
  }

  public onClickLeave(): void {
    this._mcpDeviceService.clbk_disconnect();
    this._authGuard.logout();
    this.drawer.close();
    this.drawerRight.close();
  }
}
